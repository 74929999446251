import React, { useState, useEffect } from "react";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import Parallax from "../components/parallax/Parallax";
import parallax from "./../components/parallax";
import song from "../assets/web_background_music.mp3";
import "./Main.css";
import TextComponent from "./../components/TextComponent";

import opensea from "../assets/Group 148.png";
import twitter from "../assets/twitter.png";

const links = [
  { src: twitter, url:"https://twitter.com/PGodjira", name: "Twitter" },
  { src: opensea, url:"https://opensea.io/collection/projectgodjiragenesis", name: "Opensea" },
];

const Main = (props) => {
  const [play, setPlay] = useState(false);
  useEffect(() => {
    parallax();
  }, []);

  const playAudio = (type) => {
    const audio = document.getElementById("playAudio");
    if (type === "start") {
      audio.play();
      setPlay(true);
    } else {
      audio.pause();
      setPlay(false);
    }
  };

  return (
    <div id="parallax">
      <Parallax />
      <div>
        <div className="header-component">
          {/* <div>
            <img
              style={{
                width: "576px",
                height: "60px",
              }}
              src={require("../assets/ui-logo.png")}
            />
          </div> */}
          <div className="list-items">
            {links.map((item, i) => (
              <div className="element" key={i}>
                <a className="link" href={item.url} target="_blank">
                    <img
                      src={item.src}
                      alt=""
                      style={{
                        width: "28px",
                        height: "28px",
                      }}
                      title={item.name}
                    />
                </a>
              </div>
            ))}
        </div>
          <div>
            {play ? (
              <VolumeMuteIcon
                className="mute"
                onClick={() => playAudio("stop")}
              />
            ) : (
              <VolumeOffIcon
                className="mute"
                onClick={() => playAudio("start")}
              />
            )}
            <audio id="playAudio" loop={true}>
              <source src={song} />
            </audio>
          </div>
        </div>
        <TextComponent />
      </div>
    </div>
  );
};

export default Main;
