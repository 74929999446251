import React, { useState, useEffect } from "react";

import {
  isApprovedForAll,
  balanceOfBatch,
  setApprovedForAll,
  claim,
  connectWalletHandler,
} from "./../contract/contractInteraction";

const TextComponent = (props) => {
  const reloadWindow = () => {
    window.location.reload();
  };

  const [address, setAddress] = useState("");

  const [connected, setConnected] = useState(false);

  const [jiraClaim, setJiraClaim] = useState(false);

  const [isApproved, setIsApproved] = useState(false);

  const [token, setTokens] = useState(false);

  const [loader, setLoader] = useState(false);

  const [tokenString, setTokenString] = useState("");

  const check = async () => {
    setLoader(true);
    const response = await isApprovedForAll();
    setIsApproved(response);
    const e = await balanceOfBatch();
    console.log(e, "Tokens");
    if (!e) {
      setJiraClaim(true);
    }
    setTokens(e);
    if (e) {
      let newArray = [];
      let count = 0;
      e.map((item) => {
        count++;
        if (e.length !== count) {
          newArray.push(` #${item}`);
        } else {
          newArray.push(` #${item}`);
        }
      });
      setTokenString(newArray.toString());
    }
    setLoader(false);
  };

  const connectWallet = async () => {
    console.log("CLICKED ON Connect wallet")
    await connectWalletHandler(setLoader, setConnected, setAddress)
  }

  useEffect(() => {
    if(connected){
      check();
    }
  }, [connected]);

  return (
    <div className="textContainer">
      <div className="gen-2-logo">
        <img
          style={{
            width: "650px",
            height: "240px",
            marginBottom: "2%",
            marginTop: "2%"
          }}
          src={require("../assets/download.png")}
        />
      </div>
      {loader ? (
        <>
          <img
            style={{
              width: "15vw",
              height: "33vh",
              marginLeft: "16%",
              marginTop: "-5%",
            }}
            alt="loader"
            src={require("../assets/jirahype.gif")}
          />
          <div className="message-loader">LOADING...</div>
        </>
      ) : (
        <div className="connect-mint-button">
          {connected ? (
            isApproved ? (
              <>
                <div className="wallet-address">{address}</div>
                {jiraClaim ? (
                  <>
                    <div className="message">
                      SORRY, YOU DO NOT HAVE A GENESIS GODJIRA TO MIGRATE
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="connect-wallet-button"
                      onClick={() => {
                        claim(token, setLoader, reloadWindow);
                      }}
                    >
                      CLAIM NEW
                    </button>
                    <div className="number-of-mint">
                      {/* Here 2343 should come from contract function */}
                      YOU OWN JIRA {tokenString}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="wallet-address">{address}</div>
                <button
                  className="connect-wallet-button"
                  onClick={() => {
                    setApprovedForAll(
                      setLoader,
                      setApprovedForAll,
                      setIsApproved
                    );
                  }}
                >
                  APPROVE
                </button>
                <div className="number-of-mint">
                  {/* Here 2343 should come from contract function */}
                  {/* YOU OWN JIRA {tokenString} */}
                </div>
              </>
            )
          ) : (
            <>
              <div
                className="long-text"
                style={{
                  color: "#FFFD41",
                  width: "550px",
                  height: "130px",
                  fontSize: "33px",
                  paddingLeft: "4%",
                  paddingTop: "4%",
                  textAlign: "center",
                }}
              >
                333 GENESIS GODJIRA READY TO MIGRATE TO A NEW AND POWERFUL HABITAT
              </div>
              <button
                className="connect-wallet-button"
                onClick={() =>
                  connectWallet()
                }
              >
                <span>
                  <img
                    style={{
                      marginRight: "12px",
                      width: "30px",
                      height: "30px",
                    }}
                    src={require("../assets/wallet.png")}
                  />
                </span>
                CONNECT
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TextComponent;
