import React from "react";
import "./parallax.css";
const Parallax = () => {
  return (
    <>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
    </>
  );
};

export default Parallax;
