import {ethers} from 'ethers'
import migration_abi from './migration_abi.json'
import old_abi from './old_abi.json'
import {map_list} from './map'
import {id_list} from './ids'

import React, { useState, useEffect } from "react";

let address
let oldContractAddress
let migrationContractAddress
let oldContract
let migrationContract
export const providerHandler = async () => {

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    address = account[0];
    const signer = provider.getSigner();
  
    oldContractAddress = "0x495f947276749Ce646f68AC8c248420045cb7b5e";
    migrationContractAddress = "0x9ada21A8bc6c33B49a089CFC1c24545d2a27cD81";
    
    const migrationAbi = migration_abi
    const oldAbi = old_abi
      
    oldContract = new ethers.Contract(oldContractAddress, oldAbi, signer);
    migrationContract = new ethers.Contract(migrationContractAddress, migrationAbi, signer);
    console.log('contracts init done')
};

export const get_address = async () => {
    return address;
}

export const mapId = async (id) => {
    return map_list[id]
}
// Read functions for old Contract
export const isApprovedForAll = async () => {
   const n = await oldContract.isApprovedForAll(address,migrationContractAddress);
   console.log('isApproved',n)
   return n
}

export const balanceOfBatch = async () => {
   let ids=[];
   const address_array = Array(333).fill(address)
   // const id_array = Array.from(Array(333), (_, index) => index + 1);
    const id_array = id_list
   // console.log('checking',address_array,id_array)
   const n = await oldContract.balanceOfBatch(address_array,id_array)
   // console.log(typeof(n),n)
   for(let i =0; i<333; i++){
      // console.log(n[i].toNumber())
      if(n[i].toNumber()!==0){
         ids.push(await mapId(id_array[i].toString()))
      }
   }
   console.log(ids)
   if(ids.length===0){
      return false
   }
   else{
      return ids
   }
}

//Write functions for old Contract
export const setApprovedForAll = async(setLoader, reloadWindow, setIsApproved)=>{
   try{
      setLoader(true)
      const n = await oldContract.setApprovalForAll(migrationContractAddress,true)
      await n.wait()
      console.log(setLoader)
      setLoader(false)
      setIsApproved(true)
      reloadWindow(); 
   }catch(e){
      setLoader(false)
      reloadWindow(); 
   }
   
}

//Write functions for migration Contract
export const claim = async(indexes, setLoader, reloadWindow) => {
    try{
      setLoader(true)
      const n = await migrationContract.claim(indexes)
      await n.wait()
      setLoader(false)
      alert("SUCCESSFULLY MIGRATED")
      reloadWindow(); 
    }catch(e){
      //alert("No Genesis Jira found in your wallet")
      setLoader(false)
      reloadWindow(); 
    }
}

export const connectWalletHandler = async (setLoader, setConnected, setAddress) => {
   setLoader(true);
   if (window.ethereum) {
     window.ethereum
       .request({ method: "eth_requestAccounts" })
       .then(async (result) => {
         await providerHandler();
         setConnected(true);
         get_address().then((e) => {
           let strFirstThree = e.substring(0, 5);
           let strLastThree = e.substr(e.length - 5);
           const addr = `${strFirstThree}....${strLastThree}`;
           setAddress(addr);
         });
       })
       .catch((e) => {});
   }
   setLoader(false);
};